.formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

    .errorContainer{
        background-color: rgb(255, 148, 148);
        margin-top: 1%;
        width: auto;
        min-width: 40%;
    }

    .ruleRightContainer > label{
       justify-content: center;
    }

    .formControlContainer{
        width: 75%;
        margin-bottom: 3%;
    }
    .ruleRightContainer{
        justify-content: center;
    }

    .MuiGrid-root {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sendRequestButton{
        background-color: #004426;
    }

    .sendRequestButton:hover{
        background-color: #004426;
    }
}