@import '../../../Shared/Misc/variables.scss';

#scoresMobileContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;

    .roundsSelector{
        position: sticky;
        top: calc($site-header-height + $quiz-tabs-height);
        background-color: $base-color-body;
        z-index: 100;
    }

    #quizTeams {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .quizTeamRow {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.75rem 1rem;
            background-color: #f9f9f9;
            border: 1px solid #ddd;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

            &:hover {
                background-color: #f1f1f1;
            }

            .quizTeamName {
                font-size: 1.1rem;
                font-weight: 500;
                color: #333;
            }

            .pointControls {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                
                .scoreInputField{
                    width:90px;
                }

                .controlButton {
                    color: white;
                    border-radius: 50%;
                    width: 36px;
                    height: 36px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.decrement {
                        background-color: #f44336;

                        &:hover {
                            background-color: #d32f2f;
                        }
                    }

                    &.increment {
                        background-color: #4caf50;

                        &:hover {
                            background-color: #388e3c;
                        }
                    }
                }
            }
        }
    }
}
