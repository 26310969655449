.dialog-title {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  position: relative;

  .close-button {
    position: absolute;
    right: 8px;
    top: 8px;
    color: white;
  }
}

.dialog-actions {
  display: flex;
  justify-content: space-between;
  
  .button {
    flex-grow: 1;
    margin-right: 10px;
  }

  .button:last-child {
    margin-right: 0;
  }
}
