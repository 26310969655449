#quiz-team-manager-container-fullscreen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "addQuizPlayerContainer teamManagerContainer"
        "quizTeamActionsContainer quizTeamActionsContainer"
        "unassingedTeamContainer teamsContainer";
    width: 100%;
    gap: 20px;

    #add-quiz-player-container {
        grid-area: addQuizPlayerContainer;
        width:100%;

        #add-existing-player-container {
            display: flex;
            align-items: row;
          }
          
          #invite-player-container {
            margin-top: 15px;
            display: flex;
            align-items: row;
          }
          
          #add-guest-container {
            margin-top: 15px;
            display: flex;
            align-items: row;
          }
        
          #add-player-container {
            width: 500px;
          }
    }

    #quiz-team-actions-container {
        grid-area: quizTeamActionsContainer;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        #quiz-team-actions-bar{
            display: flex;
            flex-direction: row;
            gap:20px;
            
            background-color: #ffffff;
            border-radius: 20px;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 5px;
            padding-bottom: 5px;

            .action-button{
                color: #1976d2;
            }
        }
    }

    #unassigned-team-container {
        grid-area: unassingedTeamContainer;
        width: 100%;
    }

    #quiz-teams-container {
        grid-area: teamsContainer;
        width: 100%;
    }

    #add-quiz-team-container {
        grid-area: teamManagerContainer;
        width: 100%;

        #add-guest-quiz-team{
            display: flex;
            align-items: row;

            button{
                margin-left:15px;
            }
        }
    }

    .quiz-team-card {
        background-color: #FFF;
        margin: 10px;
        min-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        border-radius: 8px;
        border: 2px solid #333;
        border-radius: 30px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        &.quiz-team-card-unassigned {
            background-color: #ffebcc;
            .quiz-players {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                flex-wrap: wrap;
                .quiz-player-card {
                    width: 45%;
                }
            }
        }

        &.quiz-team-card-assigned {
            background-color: #b3d9ff;
            .quiz-players {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                flex-wrap: wrap;
                .quiz-player-card {
                    width: 45%;
                }
            }
        }

        &.quiz-team-card-quiz-player-over {
            background-color: #e0ffe0;
        }

        &.quiz-team-card-existing{
            .quiz-team-header{
                .title{
                    font-weight: bold;
                }
            }
        }

        .quiz-team-header {
            display: flex;
            flex-direction: row;
            font-size: 32px;
            width: 100%; 
            justify-content: space-between; 
            align-items: center;
            position: relative;
        
            .title {
                flex-grow: 1;
                text-align: center;
            }

            .quiz-team-hover-controls {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                padding-left: 10px;
                padding-right: 10px;
                opacity: 0;
                transition: opacity 0.3s ease;
                cursor: pointer;
        
                background-color: #d6eaff;
                border: 2px solid #000000;
                padding: 5px;
                border-radius: 30px;

                &.unassigned{
                    background-color: #ffe99b;
                    border: 2px solid #beaf5b;
                }
            }
        
            &:hover {
                .quiz-team-hover-controls {
                    opacity: 1;
                }
            }
        }
        
        .quiz-players {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            width: 100%;

            .quiz-player-card {
                display: flex;
                align-items: center;
                position: relative;
                overflow: hidden;
                width: 100%;
                padding: 10px;
                margin: 5px;
                border-radius: 30px;
                border: 2px solid transparent;

                &:hover {
                    cursor: grab;
                }

                &.quiz-player-card-regular {
                    background-color: #ddffff;
                    border: 2px solid #73c0da;

                    .quiz-player-hover-controls {
                        background-color: #d6f8f8;
                        border: 2px solid #73c0da;
                    }
                }

                &.quiz-player-card-registered {
                    font-weight: bold;
                }

                &.quiz-player-card-unregistered {
                    font-weight: normal;
                }

                &.quiz-player-card-pending {
                    background-color: rgba(255, 217, 102, 0.67);
                    border: 2px solid #f0ad4e;

                    .quiz-player-hover-controls {
                        background-color: rgb(255, 236, 179);
                        border: 2px solid #f0ad4e;
                    }

                    .quiz-player-card-drag-overlay {
                        background-color: #807700;
                        border: 2px solid #f0ad4e;
                    }
                }

                &.quiz-player-card-accepted {
                    background-color: rgba(76, 200, 76, 0.47);
                    border: 2px solid #5cb85c;

                    .quiz-player-hover-controls {
                        background-color: rgb(166, 231, 166);
                        border: 2px solid #5cb85c;
                    }
                }

                &.quiz-player-card-declined {
                    background-color: rgba(255, 111, 111, 0.77);
                    border: 2px solid #d9534f;

                    .quiz-player-hover-controls{
                        background-color: rgb(255, 157, 157);
                        border: 2px solid #d9534f;
                    }
                }

                .quiz-player-avatar {
                    min-width: 30px;
                    min-height: 30px;
                    border-radius: 50%;
                    background-color: #007bff;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 15px;
                    font-weight: bold;
                    margin-right: 10px;
                }

                .quiz-player-name {
                    flex-grow: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .quiz-player-hover-controls {
                    display: flex;
                    align-items: flex-start;
                    gap: 5px;
                    margin-right: 5px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 25px;
                    opacity: 0;
                    transition: opacity 0.3s;
                }

                &:hover .quiz-player-hover-controls {
                    display: flex;
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }
    }
}

.edit-quizteam-dialog{
    .edit-quizteam-name{
        margin-top: 5px;
    }
}

.randomize-quizteam-dialog{
        .MuiPaper-root {
          width: 900px;
          max-width: 80%;
          margin: auto;
        }

        .teamdistribution-title-row{
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

    .randomized-quizteams-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
    
        .randomized-quizteam-item{
            min-width: 250px;
            background-color: #dff1ff;
            border-radius: 5px;
            padding: 10px;
    
            .randomized-quizteam-title{
                font-weight: bold;
            }
        }
    }
}
