.quiz-player-list-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 0 10px;
  }
  
  .sticky-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 10px 0;
    z-index: 1000;
    border-bottom: 1px solid #ccc;
  }
  
  .send-email-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .send-email-button:hover {
    background-color: #0056b3;
  }
  
  .player-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .player-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}
  
  .player-name {
    margin-left: 10px;
    font-size: 16px;
  }

  .player-email {
    font-size: 12px;
    margin-left: auto; 
    text-align: right;
}
  