.dropzone {
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 16px;
    text-align: center;
    cursor: pointer;
    background-color: white;
  
    &.active {
      background-color: azure;
    }
  }