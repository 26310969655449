.answers-container{
    background-color: rgb(243, 246, 252);
    border-radius: 4px;
    padding: 10px;
    gap: 5px;

    display: flex;
    flex-direction: column;

    .answer-item{
        display: flex;
        align-items: center;
        gap: 15px;

        .delete-answer-button{
            height: 53px;
        }
    }

    .answer-add-button{
        width: 100%;
    }
}