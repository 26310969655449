@import '../../../Shared/Misc/variables.scss';

.scoresTableContainer{
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: auto;
}

.scoreInputField {
    width: 110px;
  }
  
  .scoreTable {
    text-align: center;
    min-width: 200px;
    padding: 8px;
  }


.sticky {
    position: sticky;
    background: #fff;
    z-index: 2;
  }
  
  .teamNameRow {
    @extend .sticky;
    left: 0;
    z-index: 3;
  }

  .teamNameCell {
    @extend .teamNameRow;
    text-align: center;
    min-width: 175px;
  }
  
  .totalScoreRow {
    @extend .sticky;
    right: 0;
    z-index: 3;
    text-align: 'center';
     min-width: 50px;
  }

  .RoundNameHeader{
    text-align: center;
    min-width: 50px;
  }
  
  .headerRow {
    @extend .sticky;
    top: 0;
    z-index: 4;
  }

  .totalPointsColumn{
    @extend .sticky;
    top: 0;
    z-index: 3;
    text-align: 'center';
  }

  .unevenRow{
    background-color: #fff;
  }

  .evenRow{
    background-color: #f5f5f5;
  }

.totalPointsOfQuizTeam{
    margin-right:4px;
}
.totalQuizPoints{
    color: gray;
}

.headerTypography {
    font-weight: bold;
    font-size: 1.25rem; 
  }
  
  .teamNameTypography {
    font-weight: bold;
    font-size: 1.25rem;
  }
  
  .totalScoreTypography {
    font-weight: bold;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  