.table-container {
  display: flex;
  flex-direction: column;
  margin-top: 2%;

  .addTeamBtn {
    margin-bottom: 2%;
  }

  .table {
    width: 100%;
    margin: auto;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    
    .even-row{
      background-color: white;
    }
    
    .odd-row{
      background-color: rgb(235, 235, 235);
    }
  }
}
.icon-cell {
  width: 24px;
  white-space: nowrap;
}

.trophy-icon {
  font-size: 24px;
  margin-right: 8px;

  &.gold-trophy {
    color: #FFD700;
  }

  &.silver-trophy {
    color: #C0C0C0;
  }

  &.bronze-trophy {
    color: #CD7F32;
  }
}
