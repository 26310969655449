#quiz-team-manager-container-mobile {
    display: flex;
    flex-direction: column;
    gap: 10px;

    #add-quiz-player-container {
        display: flex;
        flex-direction: column;
        gap: 15px;

        #add-existing-player-container,
        #invite-player-container,
        #add-guest-container {
            display: flex;
            gap: 10px;
            height: 50px;
            align-items: center;
        }

        Button {
            height: 100%;
        }
    }

    #assinged-quizteams-container {
        display: flex;
        flex-direction: column;


    }
}

#quiz-players-container {
    display: flex;
    gap: 10px;
    width: 100%;
    margin-top: 5px;
    flex-direction: column;
}

#assinged-quizteams-container{

    #assinged-quizteams-select{
        height: 50px;;
    }
}

.quiz-player-card {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px;
    border-radius: 30px;
    border: 2px solid transparent;
    width: 100%;

    &.quiz-player-card-regular {
        background-color: #ddffff;
        border: 2px solid #73c0da;

        .quiz-player-hover-controls {
            background-color: #d6f8f8;
            border: 2px solid #73c0da;
        }
    }

    &.quiz-player-card-registered {
        font-weight: bold;
    }

    &.quiz-player-card-unregistered {
        font-weight: normal;
    }

    &.quiz-player-card-pending {
        background-color: rgba(255, 217, 102, 0.67);
        border: 2px solid #f0ad4e;

        .quiz-player-hover-controls {
            background-color: rgb(255, 236, 179);
            border: 2px solid #f0ad4e;
        }
    }

    &.quiz-player-card-accepted {
        background-color: rgba(76, 200, 76, 0.47);
        border: 2px solid #5cb85c;

        .quiz-player-hover-controls {
            background-color: rgb(166, 231, 166);
            border: 2px solid #5cb85c;
        }
    }

    &.quiz-player-card-declined {
        background-color: rgba(255, 111, 111, 0.77);
        border: 2px solid #d9534f;

        .quiz-player-hover-controls{
            background-color: rgb(255, 157, 157);
            border: 2px solid #d9534f;
        }
    }

    .quiz-player-avatar {
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        background-color: #007bff;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: bold;
        margin-right: 10px;
    }

    .quiz-player-name {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .quiz-player-hover-controls {
        display: flex;
        align-items: flex-start;
        gap: 5px;
        margin-right: 5px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 25px;
        opacity: 1;
        transition: opacity 0.3s;
    }
}

#add-guest-quiz-team{
    display: flex;
    flex-direction: row;

    button{
        margin-left: 15px;
    }
}