$breakpoint-mobile: 1224px;

.quizContainer {
  margin-right: 5%;
  margin-left: 5%;

  .quiz-tabs {
    display: flex;
    justify-content: center;
  }

  .quiz-tab-content {
    margin-top: 2%;

    #round-container {

      @media(max-width: $breakpoint-mobile) {
        flex-direction: column;
      }

      #selectRound {
        margin-bottom: 2%;
        padding-top: 2%;
      }

      .selected-round-dropdown-value {
        display: flex;
        align-items: center;
      }

      .selected-round-dropdown-icon {
        margin-right: 8px;
      }
    }
  }

  #quiz-data-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .form-input {
    margin-bottom: 15px !important;
  }

  #round-container {
    display: flex;
  }

  .container {
    padding: 16px;
    border: 1px solid #ccc;
    width: 100%;
  }

  .question-list-element {
    margin-bottom: 5px;
  }

  #round-list-container {
    flex: 4;
    margin-right: 10px;
    width: auto;
  }

  #question-summary-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .question-summary-list-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: sticky;
      top: 150px;
      padding: 2px;
      min-width: 80%;

      .question-summary-item {
        display: flex;
        flex-direction: row;
        justify-content: center;

        background-color: rgb(240, 248, 255);
        border: 2px solid rgb(100, 149, 237);
        border-radius: 5px;

        transition: background-color 0.3s, border-color 0.3s, transform 0.3s;

        &:hover {
          background-color: rgb(255, 250, 205);
          border-color: rgb(218, 165, 32);
          cursor: pointer;
          transform: scale(1.02);
        }

        &.completed-question-summary-item {
          background-color: rgb(204, 255, 204);
          border-color: rgb(34, 139, 34); 
      
          &:hover {
            background-color: rgb(184, 255, 184);
            border-color: rgb(0, 128, 0);
          }
        }
      
        &.incompleted-question-summary-item {
          background-color: rgb(255, 204, 204); 
          border-color: rgb(204, 0, 0); 
        
          &:hover {
            background-color: rgb(255, 163, 163);
            border-color: rgb(255, 69, 0);
          }
        }
        

        &.active-question-summary-item {
          background-color: rgb(211, 211, 255);
          border-color: rgb(147, 112, 219);
          font-weight: bolder;
          border-width: 3px;

          &:hover {
            background-color: rgb(230, 230, 250);
            border-color: rgb(123, 104, 238);
          }
        }
      }
    }
  }

  #question-list-container {
    flex: 12;
  }

  .generate-powerpoint-button {
    width: 100%;
  }

  .round-list-element {
    margin-bottom: 10px !important;
    cursor: pointer;
  }

  .fade-out {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .dragged-element {
    position: absolute;
    opacity: 0.8;
  }

  .round-container {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 2px;
    margin-bottom: 16px;
  }

  .selected-round {
    border: 3px solid darkblue;
    background-color: azure !important;
  }

  .question-input-element {
    margin-bottom: 20px !important;
  }

  .question-input-element-answer {
    margin-bottom: 0px !important;
  }

  .question-card-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .question-container-left {
    flex: 1;
    font-weight: bold;
    font-size: larger;
  }

  .question-container-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .question-container-right:hover .question-container-button-hidden {
    display: inline-flex;
  }

  .round-container-left {
    flex: 1;
  }

  .round-container-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .round-container-right:hover .question-container-button-hidden {
    display: inline-flex;
  }

  .question-container-button {
    transition: opacity 0.3s ease;
  }

  .question-container-button-hidden {
    display: none;
  }

  .round-container-button {
    margin-left: 8px;
  }

  .round-container-button-hidden {
    display: none !important;
  }

  .round-container-drag:hover {
    cursor: grab;
  }

  .selected-round {
    border: 3px solid darkblue;
    background-color: azure !important;
  }

  .round-column-content {
    position: sticky;
    top: 150px;
  }

  #left-player-container {
    flex: 1;
  }

  #right-player-container {
    display: flex;
    align-items: center;
  }

  .guest-team:hover {
    cursor: pointer;
  }

  .existing-team {
    font-weight: bolder;
  }

  .quiz-actions-button-list {
    display: flex;
    margin-bottom: 10px;
  }

  .quiz-actions-button-list button {
    height: 50px;
    width: 50px;
    margin-right: 10px;
  }

}

.question-header {
  display: flex;
}