.changedDataListTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;

    th, td {
        border: 1px solid #ddd;
        padding: 0.75em;
        text-align: left;
    }

    th {
        background-color: #f4f4f4;
        font-weight: bold;
        font-size: 1rem;
    }

    .changedDataItemRow {
        &:nth-child(even) {
            background-color: #f9f9f9;
        }
    }
}

@media (max-width: 768px) {
    .changedDataListTable {
        font-size: 0.9rem;

        th, td {
            padding: 0.5em;
        }
    }
}
