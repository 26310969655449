@import 'variables.scss';

body {
    background-color: $base-color-body !important;

    .siteHeader {
        background-color: $base-color-header;
        position: sticky;
        top: 0;
        z-index: 1000; 
        height: $site-header-height;
        width: 100%; 
        padding: 10px 0;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }

    .quiz-tabs {
        position: sticky;
        height: $quiz-tabs-height;
        margin-bottom: 10px;
        top: $site-header-height;
        background-color: $base-color-body;
        z-index: 900;
        width: 100%;
    }

    .rounds {
        position: sticky;
        top: 120px; /* Adjust this value based on the combined height of the sticky elements above */
        z-index: 800; /* Keep this below the other elements */
        background-color: white;
    }

    .mutableAction {
        background-color: $base-color-mutable-action;
    }

    .deleteAction {
        background-color: $base-color-delete;
    }

    .imutableAction {
        background-color: $base-color-imutable-action;
    }

    .card {
        background-color: $base-color-card;
    }

    .pointerMouse {
        cursor: pointer;
    }
}
