.tableHeader{
    background-color: #067bc2 !important;
    color: white !important;
}

.MuiDataGrid-columnSeparator--sideRight{
    display: none !important;
}

.tableContainer{
    display: flex;
    align-items: center;
    flex-direction: column;

    .tableInnerContainer{
        width: 90%;
    }

    .tableHeaderContainer{
        width: 90%;
        display: flex;
        align-items: center;

        h1{
            width: 100%;
            align-self: center;
            display: flex;
            justify-content: center;
        }
    }
}
