.FileUploader-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgb(243, 246, 252);
  border-radius: 4px;
  padding: 15px;
}

.UploadProgressContainer {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;

  .UploadProgressContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
  }
  
  .UploadProgressItem {
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      gap: 8px;

      &.inProgress {
          background-color: #e0f3ff;

          .status {
              color: #0288d1;
          }
      }
  
      &.succeeded {
          background-color: #e8f5e9;

          .status {
              color: #388e3c;
          }
      }
  
      &.failed {
          background-color: #ffebee;

          .status {
              color: #d32f2f;
          }
      }

      span {
          display: flex;
          align-items: center;
      }

      .filename {
          flex: 3;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
      }

      .error {
          flex: 1;
          color: #d32f2f;
      }

      .status {
          flex: 0;
      }
  }
}

.youtube-input-container {
  display: flex;
  gap: 15px;

  .youtube-add-button{
    height: 53px;
  }
}

.AttachmentContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-top: 5px;
  margin-bottom: 5px;

  .AttachmentPreview {
    position: relative; 

    .AttachmentPreviewCardContent{
      height: 150px;
      width: auto;
    }

    .AttachmentPreviewHeader{
      background-color: #e0f3ff;
    }
      .loaderWrapper {
        position: relative;
      }

      .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

  }
  
  .deleteIcon {
    position: absolute;
    top: 8px; 
    right: 8px; 
    opacity: 0; 
    transition: opacity 0.3s ease; 
  }
  
  .AttachmentPreview:hover .deleteIcon {
    opacity: 1;
  }

  .image-thumbnail {
    width: auto;
    height: 150px;
    object-fit: cover;
  }
  
  .image-full {
    width: 100%;
  }


.youtube-thumbnail {
  width: auto;
  height: 150px;
}

.youtube-full {
  width: auto;
  height: 400px;
}

.video-thumbnail {
  width: 200px;
  height: auto;
  object-fit: cover;
}

.video-full {
  width: 100%;
  height: auto;
}

.audio-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  font-size: 75px; 
}

.audio-full {
  width: 150px;
  height: 150px;
}

}