main {
    #loginContainer {
        display: flex;
        justify-content: center;
        .quizTitleContainer {
            text-align: center;
            h1{
                font-size: 50px;
            }

            #quoteContainer{
                display: flex;
                flex-direction: column;
                align-content: center;
            
                #quoter{
                    font-style: italic;
                }
            }
        }
    }
    
    #loginAuthenticationContainer{
        display: flex;
        align-items: center;
        #loginAuthenticationImageContainer{
            flex: 1;
        }
        img{
            max-width: 60%;
            margin-left: 20%;
        }

        #loginAuthenticationButtons{
            flex: 1;
            display: flex;
            flex-direction: column;
            button{
                max-width: 50%;
                margin-top: 2%;
            }
        }
    }
}

