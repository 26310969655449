#add-quiz-player-container {
    display: flex;
    flex-direction: column;
  
    #add-existing-player-container, 
    #invite-player-container, 
    #add-guest-container {
      display: flex;
      align-items: center;
  
      button {
        margin-left: 15px;
        height: 100%; 
      }
    }
  
    #error-message {
      color: red;
      margin-top: 8px;
    }
  }