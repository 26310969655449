$base-color-body: #E6F7FF;
$base-color-header: #3498DB;
$base-color-mutable-action: #596269;
$base-color-imutable-action: #1E4880;
$base-color-delete: #D56062;
$base-color-card: #85C1E9;
$background-paper: white;


.deleteModal {
    .deleteModalBox {
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $background-paper;
      box-shadow: 24px;
      padding: 16px;
      min-width: 400px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      gap: 10px;
  
      .deleteModalContent {
        .deleteModalTitle {
          font-size: 1.25rem;
          font-weight: 500;
        }
  
        .deleteModalText {
          margin-top: 16px;
        }
      }
  
      .deleteModalActions {
        display: flex;
        justify-content: flex-end;
  
        .deleteModalCancelButton {
          background-color: $base-color-header;
        }
  
        .deleteModalDeleteButton {
          background-color: $base-color-delete;
          margin-left: 10px;
        }
      }
    }
  }
  