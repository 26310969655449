.address-container {
    display: flex;
    flex-direction: column;

    .address-fields {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 16px;
      margin-top: 16px;
      margin-bottom: 16px;
  
      // Street and number
      & > :nth-child(1) {
        grid-column: span 3;
      }
      & > :nth-child(2) {
        grid-column: span 2;
      }
  
      // Town and Postal
      & > :nth-child(3) {
        grid-column: span 3;
      }
      & > :nth-child(4) {
        grid-column: span 2;
      }
  
      // Province and Country
      & > :nth-child(5) {
        grid-column: span 3;
      }
      & > :nth-child(6) {
        grid-column: span 2;
      }
    }

    @media (max-width: 768px) {
      .address-fields {
        grid-template-columns: 1fr; /* Single column layout */
        
        & > * {
          grid-column: span 1; /* Each item takes full width */
        }
      }
    }

      
    .autocomplete-container {
      .autocomplete-input {
          width: 100%;
          margin-bottom: 6px;
      }
  
      .suggestions-list {
          list-style-type: none;
          padding: 0;
          margin: 8px 0 0;
          border: 1px solid #ccc;
          border-radius: 4px;
          max-height: 200px;
          overflow-y: auto;
  
          .suggestion-item {
              padding: 8px;
              cursor: pointer;
              background-color: #f0f0f0;
  
              &:nth-child(odd) {
                  background-color: #f7f7f7;
              }
              &:nth-child(even) {
                  background-color: #ededed;
              }
  
              &:hover {
                  background-color: #d0d0d0;
              }
          }
      }
  }  

  .AddressMap-Container{
    height: 25vh;
    margin-bottom: 16px;
  }
}

  