.siteHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 2%;

  .siteHeader__section {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    padding: 6px;
    padding: 1%;
    background-size: 10% 100%;

    a {
      font-weight: bold;
      color: white;
      padding: 10px;
      padding-bottom: 0px;
    }

    .siteHeader__item {
      padding: 5px 15px;
      font-size: 15px;
      text-decoration: none;
      background-color: rgb(0, 85, 159);
      margin-left: 10px;
      height: auto;
      border-radius: 3px;
    }

    .siteHeader__item:hover {
      background-color: rgb(52, 119, 179);
    }

    .siteHeader__logo {
      margin-right: 20px;
    }

    .siteHeader__logo-image {
      height: 35px;
      width: auto;
    }
  }}